import * as env from "$env/static/public";
import Logger from "$lib/logger";
import * as Sentry from "@sentry/sveltekit";
import { handleErrorWithSentry, Replay } from "@sentry/sveltekit";
import { withoutKeys } from "backend/common/utils";
import { AbstractError } from "backend/lib/errors";
import { browserExporter } from "backend/lib/logger/exporter";
import { LogLevelMatchVerbosity } from "backend/lib/logger/log-levels";
import type { LogJSON } from "backend/lib/logger/logger";
import type { KeyValue } from "backend/lib/utils/types";

Sentry.init({
  dsn: "https://43aedf3d1fa353cce0aaf2ceb08a9b9f@o4505873874878464.ingest.sentry.io/4505875130744832",
  environment: env.PUBLIC_ENV || "unknown",
  integrations: [
    new Replay({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  normalizeDepth: 10,
});

export const handleError = handleErrorWithSentry();

function dropSensitiveData(data: KeyValue) {
  return withoutKeys(data, ["password", "headers"]);
}

Logger.exporter = (log: LogJSON) => {
  browserExporter(dropSensitiveData(log));

  if (LogLevelMatchVerbosity(log.level, "error")) {
    const error = log.message;
    if (error instanceof AbstractError) {
      Sentry.captureException(new Error(error.head), {
        extra: dropSensitiveData(error.toJSON()),
      });
    } else {
      Sentry.captureException(error);
    }
  }
};

export default Logger;
